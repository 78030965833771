
import { defineComponent } from 'vue';
import AuthService from '@/services/AuthService';
import GameService from '@/services/GameService';
import Game from '@/models/game.model';
import router from '@/router';
import CurrencyInput from '@/components/CurrencyInput.vue';
declare const bootstrap: any;

export default defineComponent({
  name: 'Games',
  components: { CurrencyInput },
  data () {
    return {
      authService: new AuthService(),
      gameService: new GameService(),
      loading: true,
      createLoading: false,
      games: [] as Game[],
      formatter: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }),
      newGame: new Game({}),
      newGameBuyIn: 10,
      newGameDate: new Date(),
      createGameModal: {} as any
    }
  },
  mounted () {
    this.gameService.GetGames()
      .then((result) => { this.games = result })
      .finally(() => { this.loading = false });

    this.createGameModal = new bootstrap.Modal(document.getElementById('addGameModal'))
  },
  methods: {
    openGame (gameId: string) {
      router.push({ name: 'Game', params: { id: gameId } });
    },
    setNewGame () {
      this.newGameDate = new Date();
      this.newGameDate.setHours(19);
      this.newGameDate.setMinutes(0);
      this.newGameDate.setSeconds(0);
    },
    createGame () {
      this.createLoading = true;
      this.newGame.buyIn = this.newGameBuyIn;
      this.newGame.date = this.newGameDate.toISOString();
      this.gameService.CreateGame(this.newGame)
        .then((result: Game) => {
          this.createGameModal.hide();

          this.$nextTick(() => {
            if (result?.id) router.push({ name: 'Game', params: { id: result.id } })
          });
        })
        .finally(() => { this.createLoading = false });
    }
  }
})
